import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-translated';

import * as globalMessagesActions from '../../store/actions/globalMessagesActions';

import Modal from 'react-bootstrap/Modal';

function GlobalAlert() {
	const globalMessages = useSelector(state => state.globalMessages);
	const dispatch = useDispatch();
	const handleClose = async () => {
		await dispatch(globalMessagesActions.clearMessages());
		if (globalMessages && globalMessages.closeCallback) {
			globalMessages.closeCallback();
		}
	};

	if (globalMessages && globalMessages.show) {
		return (
			<>
				<Modal show={ true } onHide={ handleClose } centered className={ (globalMessages.error) ? 'modal--error' : (globalMessages.success) ? 'modal--success' : '' }>
					<Modal.Header closeButton>
						<h2>
							<Translate text={ globalMessages.message } />
						</h2>
					</Modal.Header>

					{ globalMessages.body &&
						<Modal.Body>
							{ globalMessages.body }
						</Modal.Body>
					}
				</Modal>
			</>
		);
	}

	return null;
}

export default GlobalAlert;
