import 'core-js';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store/index';
const env = window.env;

if (process.env.NODE_ENV === 'development' && env.MOCK_API) {
	const { worker } = require('./mocks/browser');
	worker.start();
}

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

if (process.env.NODE_ENV !== 'development' || !env.MOCK_API) {
	serviceWorker.unregister();
}
