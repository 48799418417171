import { authenticateConstants } from '../constants/authenticateConstants';

export function authenticate(state = {}, action) {
	switch (action.type) {
		case authenticateConstants.AUTH_REQUEST:
			return {
				request: true
			};
		case authenticateConstants.AUTH_SUCCESS:
			return {
				success: true,
				data: action.data
			};
		case authenticateConstants.AUTH_ERROR:
			return {
				error: true
			};
		default:
			return state;
	}
}
