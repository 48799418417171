import { globalMessagesConstants } from '../constants/globalMessagesConstants';

export const error = (message, body, closeCallback) => {
	return {
		type: globalMessagesConstants.ERROR,
		message,
		body,
		closeCallback
	};
};

export const success = (message, body, closeCallback) => {
	return {
		type: globalMessagesConstants.SUCCESS,
		message,
		body,
		closeCallback
	};
};

export const clearMessages = () => {
	return {
		type: globalMessagesConstants.CLEAR_MESSAGES
	};
};

export const setHasChanges = (hasChanges) => {
	if (hasChanges == false) {
		return {
			type: globalMessagesConstants.HAS_CHANGES_FALSE
		};
	}

	return {
		type: globalMessagesConstants.HAS_CHANGES_TRUE
	};
};
