import React from 'react';
import { Translate } from 'react-translated';

function SkipToMain() {
	const handleClick = (event) => {
		event.preventDefault();

		const element = document.getElementById('main');

		if (element) {
			const position = element.getBoundingClientRect().top + window.pageYOffset;
			window.scrollTo({ top: position, behavior: 'smooth' });
			element.focus();
		}
	};

	return (
		<a
			href="#main"
			className="visuallyhidden focusable skip-to-link"
			onClick={ handleClick }
		>
			<Translate text="Skip to main content" />
		</a>
	);
}

export default SkipToMain;
