import { languageConstants } from '../constants/languageConstants';
const { LANGUAGE_ENGLISH, LANGUAGE_ENGLISH_CODE } = require('../../config/constants');

export const language = (state = { value: LANGUAGE_ENGLISH, code: LANGUAGE_ENGLISH_CODE }, action) => {
	switch (action.type) {
		case languageConstants.LANGUAGE_UPDATE:
			return {
				value: action.language,
				code: action.code
			};
		default:
			return state;
	}
};
