import { globalMessagesConstants } from '../constants/globalMessagesConstants';

export const globalMessages = (state = {}, action) => {
	switch (action.type) {
		case globalMessagesConstants.ERROR:
			return {
				show: true,
				success: false,
				error: true,
				message: action.message,
				body: action.body,
				closeCallback: action.closeCallback
			};
		case globalMessagesConstants.SUCCESS:
			return {
				show: true,
				success: true,
				error: false,
				message: action.message,
				body: action.body,
				closeCallback: action.closeCallback
			};
		case globalMessagesConstants.CLEAR_MESSAGES:
			return {};
		default:
			return state;
	}
};

export const hasChanges = (state = false, action) => {
	switch (action.type) {
		case globalMessagesConstants.HAS_CHANGES_TRUE:
			return true;
		case globalMessagesConstants.HAS_CHANGES_FALSE:
			return false;
		default:
			return state;
	}
};
